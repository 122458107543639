html,
body {
  margin: 0;
  background: white;
  background-image: url("https://i.imgur.com/6TXpvHt.png");
  background-position: center;
  background-size: cover;
  background-attachment: fixed;
  background-repeat: no-repeat;
}

img {
  pointer-events: none;
  -webkit-touch-callout: none;
  image-rendering: optimizeQuality;
}

body,
h1,
h2,
p,
a,
input,
button {
  color: white;
  font-family: Helvetica;
  font-size: x-large;
  text-decoration: none;
}
ul {
  margin: 0;
  padding: 0;
}

input {
  width: 100%;
}

hr {
  margin: 2px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  width: 100%;
}

button {
  cursor: pointer;
  background-color: rgba(30, 30, 30, 1);
  border-radius: 8px;
  border-width: 0px;
  transition: 0.2s;
  padding: 5px;
}

button:hover {
  background: rgb(50, 50, 50, 1);
}

button:disabled {
  background-color: rgba(30, 30, 30, 0.6);
  cursor: default;
}

input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input[type="number"] {
  -moz-appearance: textfield;
}

input:focus,
select:focus,
textarea:focus,
button:focus {
  outline: none;
}

div::-webkit-scrollbar {
  display: none;
}

div {
  -ms-overflow-style: none;
  scrollbar-width: none;
}
