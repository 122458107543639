h1 {
  color: rgb(250, 250, 250);
  font-size: xx-large;
  padding: 0px;
  margin: 0px;
}

h2 {
  color: rgb(250, 250, 250);
  font-size: larger;
  font-weight: 500;
  padding: 0px;
  margin: 0px;
}

h3 {
  font-size: x-large;
  padding: 0px;
  margin: 0px;
  color: rgb(160, 160, 160);
  font-weight: 400;
}

input {
  color: rgb(20, 20, 20);
  font-size: large;
  font-weight: 500;
  padding: 0px;
  margin: 0px;
}

.mainBorder {
  background-color: rgba(0, 0, 0, 0.6);
  border-radius: 25px;
  border: solid 3px rgb(70, 70, 70);
}

.mainBorder:hover {
  background-color: rgba(0, 0, 0, 0.6);
  border: solid 3px rgb(60, 60, 60);
}

.mainButton,
.mainButton:disabled {
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 15px;
  border: solid 2px rgb(40, 40, 40);
  cursor: pointer;
}

.mainButton:hover {
  background-color: rgba(0, 0, 0, 0.5);
  border: solid 2px rgb(60, 60, 60);
}

.mainInput {
  background-color: rgb(10, 10, 10);
  padding: 5px;
  margin-top: 5px;
  margin-bottom: 5px;
  border-radius: 3px;
  border: none;
  color: rgb(220, 220, 220);
  font-size: 6mm;
}

.mainPadding {
  padding: 1px;
  background-color: rgb(70, 70, 70);
}

.mainBackground {
  background-color: rgba(0, 0, 0, 0.5);
}

/* input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0px 1000px rgb(10, 10, 10) inset;
  -webkit-text-fill-color: rgb(200, 200, 200);
} */

.mainText {
}

.borderDiv {
  border: 2px solid rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  padding: 20px;
}

.buttonConfirmPurchase {
  border-radius: 20px;
  border: 1px solid rgba(0, 0, 0, 0.2);
  padding: 6px 18px;
  box-shadow: none;
  background-color: rgba(0, 0, 0, 0);
  color: rgb(20, 20, 20);
  font-size: large;
  font-weight: 500;
  margin: 0px 5px;
}

.buttonConfirmPurchase:hover {
  background-color: rgba(0, 0, 0, 0);
  border: 1px solid rgba(0, 0, 0, 0.8);
}

.tabInactive {
  border-radius: 20px;
  padding: 6px 18px;
  background-color: rgba(200, 200, 200, 0.5);
  box-shadow: none;
  color: rgba(0, 0, 0, 0.8);
  font-size: large;
  font-weight: 500;
  margin: 0px 5px;
}

.tabInactive:hover {
  background-color: rgb(200, 200, 200);
}

.mainDiv {
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 4px 4px 10px rgba(0, 0, 0, 0.2);
  background-color: rgba(30, 30, 30, 0.8);
  border: 1px solid rgba(128, 128, 128, 0.5);
}

.creditTab {
  width: 260px;
}

.buttonDownload {
  background: linear-gradient(to right, rgb(255, 103, 56), rgb(240, 140, 115));
  border-radius: 20px;
  padding: 10px 25px 10px 15px;
  font-weight: 700;
  font-size: xx-large;
  color: rgb(250, 250, 250);
  display: flex;
  align-items: center;
  justify-content: center;
}

.buttonDownload:hover {
  background: linear-gradient(to right, rgb(233, 86, 42), rgb(216, 116, 91));
}

.buttonPurchase {
  background: linear-gradient(to right, rgb(50, 30, 30), rgb(60, 45, 45));
  border-radius: 20px;
}

.buttonPurchase:hover {
  transform: scale(1.02);
  background: linear-gradient(to right, rgb(50, 30, 30), rgb(60, 45, 45));
}

.paragraphs {
  max-height: 300px;
}

.stripeDiv {
  padding: 20px;
  background-color: white;
  border-radius: 20px;
  border: 1px solid rgba(0, 0, 0, 0.5);
  top: 50%;
  left: 50%;
  position: fixed;
  width: 400px;
  transform: translate(-50%, -50%);
}

.optionsButton {
  background: rgb(30, 30, 30, 0.8);
  border: 1px solid rgba(128, 128, 128, 0.5);
  border-radius: 50%;
}

.optionsButton:hover {
  background: rgb(50, 50, 50, 1);
}

@media only screen and (max-width: 600px), screen and (orientation: portrait) {
  .buttonDownload {
    font-size: x-large;
  }
  .paragraphs {
    max-height: 0px;
  }
  .creditTab {
    width: 100%;
    max-width: 400px;
  }
  .stripeDiv {
    width: 80vw;
    max-height: 90vh;
  }
}

.creditButtonActive {
  border-width: 2px;
  border-color: rgba(60, 120, 220, 1);
  border-radius: 6px;
  padding: 6px 18px;
  box-shadow: none;
  color: rgba(60, 120, 220, 1);
  font-weight: 500;
  font-size: xx-large;
  margin: 0px 5px;
  justify-content: center;
  align-items: center;
  border-style: solid;
}

.paragraphScroll::-webkit-scrollbar {
  width: 15px;
  display: block;
  height: 0px;
}

.paragraphScroll::-webkit-scrollbar-track {
  background: rgba(200, 200, 200, 1);
  border-radius: 100vw;
}

.paragraphScroll::-webkit-scrollbar-thumb {
  background: rgba(60, 120, 220, 1);
  margin: 5px;
  border-radius: 100vw;
  border: 3px solid rgba(200, 200, 200, 1);
}
.creditButtonInactive {
  border-width: 2px;
  border-color: rgba(0, 0, 0, 0.3);
  border-radius: 6px;
  padding: 6px 18px;
  box-shadow: none;
  color: rgba(0, 0, 0, 0.3);
  font-weight: 500;
  font-size: xx-large;
  margin: 0px 5px;
  justify-content: center;
  align-items: center;
  border-style: solid;
}

.linkSquare {
  background-color: whitesmoke;
  cursor: pointer;
  font-family: Cambria, Cochin, Georgia, Times, "Times New Roman", serif;
}

.standardSquare {
  background-color: whitesmoke;
  font-family: Cambria, Cochin, Georgia, Times, "Times New Roman", serif;
  overflow-wrap: break-word;
}

.textAreaDefault {
  text-shadow: none;
}

.textAreaStrokeBig {
  text-shadow: -2px -2px 0 #000, 0 -2px 0 #000, 2px -2px 0 #000, 2px 0 0 #000,
    2px 2px 0 #000, 0 2px 0 #000, -2px 2px 0 #000, -2px 0 0 #000;
}

.textAreaStrokeSmall {
  text-shadow: -1px -1px 0 #000, 0 -1px 0 #000, 1px -1px 0 #000, 1px 0 0 #000,
    1px 1px 0 #000, 0 1px 0 #000, -1px 1px 0 #000, -1px 0 0 #000;
}

.masonryMain {
  display: -webkit-box; /* Not needed if autoprefixing */
  display: -ms-flexbox; /* Not needed if autoprefixing */
  display: flex;
}
